import { InvoiceSource } from './invoice';

import { PaymentMethod, PaymentMethodType } from './payment-method.model';

export class PaymentInvoice extends PaymentMethod {
  constructor(public invoiceSource: InvoiceSource) {
    super(
      'INVOICE', // id set to INVOICE - ONLY ONE INVOICE METHOD POSSIBLE
      'INVOICE', // method name = invoice
      invoiceSource.invoiceEmail, // shortDescription
      PaymentMethodType.INVOICE // type
    );
  }
}
